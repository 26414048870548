
/**
 * @name: 营销管理-小程序推荐商品
 * @author: itmobai
 * @date: 2023-06-02 09:20
 * @description：营销管理-小程序推荐商品
 * @update: 2023-06-02 09:20
 */
import {Component, Vue} from "vue-property-decorator";
import config from "@/config";
import {ICrudOption} from "@/types/m-ui-crud";
import {IMarketShop} from "@/apis/marketingManage/shop/types";
import {
  recommendProductBatchDeleteApi, recommendProductCreateApi,
  recommendProductModifyApi,
  recommendProductQueryRecommendProductListApi, recommendProductUpAndDownShelfApi
} from "@/apis/marketingManage/shop";
import {queryProductListApi} from "@/apis/product";
import {productsortQueryProductSortListApi} from "@/apis/product/classification";
import {deepCopy} from "@/utils/common";
import {IInternetOrderParam} from "@/apis/order/internet/types";
import {Message} from "element-ui";

@Component({})
export default class MarketShopPage extends Vue {
  config = config;
  // 表格数据
  tableData: IMarketShop[] = []
  // 选择的数据
  selectIds: string[] = []
  // 添加弹窗
  addDialog: boolean = false;
  // 表格总数
  tableTotal: number = 0;
  queryParam: any = {
    page: 1,
    limit: 10
  }
  addTableTotal: number = 0
  list: any = []
  query: any = {
    page: 1,
    limit: 10,
    status: 1
  }
  modelForm: any = {}
  modelFormSort: number = 1

  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: '商品ID',
        prop: 'id',
        width: 180,
        align: 'center'
      },
      {
        label: '商品名称',
        prop: 'productName',
        align: 'center',
        overHidden: true,
        search: true
      },
      {
        label: '商品分类',
        prop: 'sortId',
        search: true,
        searchSlot: true,
        hide: true
      },
      {
        label: '商品分类',
        prop: 'sortName',
        align: 'center',
        width: 150,
      },
      {
        label: '商品售价',
        prop: 'productPrice',
        align: 'center',
        width: 150
      }
    ]
  }
  shopCates: any = []
  addSelectRows: any = []

  /**
   * 查询列表
   */
  getList () {
    recommendProductQueryRecommendProductListApi(this.queryParam).then(e => {
      this.tableData = e.list;
      this.tableTotal = e.total
    })
  }

  avatarUploadSuccess (id: string, key: string, res: any, file: any) {
    const { url } = res.data;
    const obj: Partial<IMarketShop> = {id}
    // @ts-ignore
    obj[key] = url;
    if (key !== 'sort') {
      let item = this.tableData.find(item => item.id === id)
      if (item) {
        obj['sort'] = item.sort
      }
    }
    recommendProductModifyApi(obj).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  beforeAvatarUpload () {

  }

  /**
   * 更新列表配置
   * @param id
   * @param key
   * @param value
   */
  updateRow (id: string, key: string, value: string) {
    if (key === 'sort') {
      if (!/^[1-9]\d{0,3}$/.test(value)) {
        this.getList()
        return this.$message.error("请输入排序为正整数，最大长度4位数")
      }
    }
    const obj: Partial<IMarketShop> = {id}
    // @ts-ignore
    obj[key] = value;
    recommendProductModifyApi(obj).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  currentChange(val:any){
    this.queryParam.page = val
    this.getList()
  }

  sizeChange(val:any){
    this.queryParam.limit = val
    this.getList()
  }

  handleSelectionChange (val: any) {
    this.selectIds = val.map((item: any) => item.id)
  }

  batchDelete (ids: string[]) {
    if (!ids.length) {
      return this.$message.error("请选择数据")
    }
    this.$confirm('是否确认移除？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      recommendProductBatchDeleteApi(ids.join(",")).then(e => {
        if (e) {
          this.$message.success("操作成功!")
          this.getList()
        }
      })
    }).catch(() => {
    });
  }

  handleSwitch (id: string, val: number) {
    recommendProductUpAndDownShelfApi(id, val).then(e => {
      this.$message.success("操作成功!")
      this.getList()
    })
  }

  openAddDialog () {
    this.modelFormSort = 1
    this.query.page = 1
    this.query.limit = 10
    this.list = []
    this.addSelectRows = []
    this.addDialog = true
    this.getShopList()
  }

  getShopList () {
    queryProductListApi(this.query).then(e => {
      this.addTableTotal = e.total;
      this.list = e.list;
    })
  }

  /**
   * 上传前检验
   * @param file
   */
  beforeUpload(file: File) {
    if (file.type != "image/png" && file.type != "image/jpg" && file.type != "image/jpeg") {
      Message.error("请选择正确格式")
      return false
    }
  }

  /**
   * 商品分类下拉列表
   */
  getShopCateSelect () {
    productsortQueryProductSortListApi().then(e => {
      this.shopCates = e;
    })
  }

  handleChoose () {
    if (!this.addSelectRows.length) {
      return this.$message.error("请选择数据")
    }
    if (!/^[1-9]\d{0,3}$/.test(String(this.modelFormSort))) {
      return this.$message.error("请输入排序为正整数，最大长度4位数!")
    }
    const obj: any = deepCopy(this.addSelectRows[0])
    obj.sort = this.modelFormSort
    obj.productId = this.addSelectRows[0].id
    delete obj.id
    recommendProductCreateApi(obj).then(e => {
      this.$message.success("操作成功!")
      this.getList()
      this.addDialog = false
    })
  }

  created () {
    this.getList()
    this.getShopCateSelect()
  }
}
