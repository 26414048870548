import {get, postJ} from "@/request";
import {IPageRes} from "@/apis/page";
import {IProduct, IProductQuery, IProductStatus} from "@/apis/product/types";



/**
 * 商品列表 -分页
 * @param params 查询参数
 */
export const queryProductListApi = (params: IProductQuery) => get<IPageRes<IProduct[]>>("/golf/product/query", params)

/**
 * 添加
 */
export const createProductApi = (data: IProduct) => postJ("/golf/product/create", data)
/**
 * 修改
 */
export const modifyProductApi = (data: IProduct) => postJ("/golf/product/modify", data)
/**
 * 查询详情
 * @param id
 */
export const getProductDetailApi = (id: string) => get<IProduct>("/golf/product/detail/" + id)

/**
 * 切换状态
 * @param id,status
 */
export const modifyProductStatusApi = (data: IProductStatus) => postJ("/golf/product/upAndDownShelf",data)
/**
 * 商品品牌列表
 */
export const getProductBrandListApi = () => get("/golf/product/getProductBrandList", {})
