/**
 * @name: 商品分类接口
 * @author: itmobai
 * @date: 2023-05-31 14:28
 * @description：商品分类接口
 * @update: 2023-05-31 14:28
 */
import {get, postJ} from "@/request";
import {ISort, ISortParam} from "@/apis/product/classification/types";

/**
 * 商品分类查询
 * @param param 查询参数
 */
export const productsortQueryByListApi = (param: ISortParam) => get<ISort[]>("/golf/productsort/queryByList", param)
/**
 * 商品分类修改
 * @param data 修改数据
 */
export const productsortModifyApi = (data: Partial<ISort>) => postJ("/golf/productsort/modify", data)
/**
 * 商品分类下拉列表
 */
export const productsortQueryProductSortListApi = () => get<ISort[]>("/golf/productsort/queryByList")
/**
 * 商品分类创建
 * @param data
 */
export const productsortCreateApi = (data: ISort) => postJ("/golf/productsort/create", data)
/**
 * 商品分类删除
 * @param id
 */
export const productsortRemoveApi = (id: string) => get("/golf/productsort/remove/" + id)
/**
 * 切换状态
 * @param id
 * @param status
 */
export const productsortStatusUpdateApi = (id: string, status: number) => postJ("/golf/productsort/statusUpdate", {id, status})
